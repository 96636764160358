<template>
  <button
    class="transform rounded-full text-black disabled:opacity-50"
    :disabled="carousel?.currentIndex === 0"
    @click="carousel?.prevSlide"
  >
    <svg
      :class="
        classes(
          size === 'small' && 'h-6 w-6',
          (!size || size === 'medium') && 'h-12 w-12',
          size === 'large' && 'h-12 w-12'
        )
      "
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
        d="M15 19l-7-7 7-7"
      ></path>
    </svg>
  </button>
  <Carousel
    ref="carousel"
    class="carousel"
    :slides-data="slidesData"
    :slide-component="slideComponent"
    :slides-per-page="slidesPerPage"
  />
  <button
    :disabled="carousel?.currentIndex === slidesData.length - 1"
    class="right-arrow transform rounded-full text-black disabled:opacity-50"
    @click="carousel?.nextSlide"
  >
    <svg
      :class="
        classes(
          size === 'small' && 'h-6 w-6',
          (!size || size === 'medium') && 'h-12 w-12',
          size === 'large' && 'h-12 w-12'
        )
      "
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7"></path>
    </svg>
  </button>
</template>

<script lang="ts" setup>
import Carousel from '@/components/Carousel.vue';

interface Props {
  slidesData: Object[];
  slideComponent: Object;
  slidesPerPage?: number;
  displayDefaultArrows?: boolean;
  arrowsSize?: 'small' | 'medium' | 'large';
  loop?: boolean;
  size?: 'small' | 'medium' | 'large';
}

defineProps<Props>();

const carousel = ref<InstanceType<typeof Carousel> | null>(null);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';

.carousel {
  width: 90%;
  height: 110%;
}

@include breakpoints.tablet() {
  .carousel {
    width: 75%;
  }

  .right-arrow {
    position: absolute;
    right: 0;
  }
}
</style>
